import { SEND_MESSAGE, MESSAGE_LOADING } from "../actions/types";

const initialState = {
  messages: [],
  message: {},
  loading: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case MESSAGE_LOADING:
      return {
        ...state,
        loading: true
      };

    case SEND_MESSAGE:
      return {
        ...state,
        messages: state.messages.concat(action.payload),
        loading: false
      };
    default:
      return state;
  }
}

import React, { Component } from "react";

class Navigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isActive: false,
    };
    this.toggleActive = this.toggleActive.bind(this);
  }

  toggleActive() {
    this.setState((prevState) => ({
      isActive: !prevState.isActive,
    }));
  }

  render() {
    return (
      <nav className="navbar is-spaced">
        <div className="container">
          <div className="navbar-brand">
            <a className="navbar-item" href="/">
              Mahmod Hasan
            </a>
            <a
              className="navbar-burger    "
              role="button"
              aria-label="menu"
              aria-expanded="false"
              onClick={() => {
                this.toggleActive();
              }}
            >
              <span aria-hidden="true" />
              <span aria-hidden="true" />
              <span aria-hidden="true" />
            </a>
          </div>
          <div
            className={
              this.state.isActive
                ? "navbar-menu is-active has-background-white-bis"
                : "navbar-menu"
            }
          >
            <div className="navbar-end">
              <a className="navbar-item" href="/projects">
                Projects
              </a>
              <a className="navbar-item" href="/enterprise">
                Enterprise
              </a>
              <a className="navbar-item" href="/contact">
                Contact
              </a>
              <div className="navbar-item has-dropdown is-hoverable">
                <a className="navbar-link">More</a>
                <div className="navbar-dropdown">
                  <a className="navbar-item navbar-item-dropdown" href="/blog">
                    Blog
                  </a>
                  <a
                    className="navbar-item navbar-item-dropdown"
                    href="get-to-know-me"
                  >
                    Get to Know me...
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

export default Navigation;

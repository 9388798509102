import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ProjectsFeed from "./ProjectsFeed";
import Spinner from "../../components/common/Spinner";
import OptionsFeed from "./OptionsFeed";
import {
  getAllProjects,
  getAllLimitProjects,
} from "../../actions/projectsActions";

class Projects extends Component {
  constructor() {
    super();
    this.state = {
      selected: "All",
    };
    this.setSelected = this.setSelected.bind(this);
  }

  setSelected(e) {
    let text = e.currentTarget.text.trim();
    this.setState({ selected: text });
  }

  componentDidMount() {
    if (Object.keys(this.props.project.projects).length === 0) {
      this.props.getAllProjects();
    }
  }

  render() {
    const { projects, loading } = this.props.project;
    let projectsContent;
    if (Object.keys(projects).length === 0 || loading)
      projectsContent = <Spinner />;
    else
      projectsContent = (
        <ProjectsFeed
          projects={projects}
          limit={this.props.limit}
          selected={this.state.selected}
        />
      );
    return (
      <section className="section">
        <div className="container py-4">
          <h2 className="title has-text-centered mb-6">Recent Projects</h2>
          {this.props.limit === 1 || this.props.limit === 1 ? (
            <section>
              <ul className="filter_list">
                <OptionsFeed
                  setSelected={this.setSelected}
                  selected={this.state.selected}
                />
              </ul>
            </section>
          ) : null}
          <div className="columns is-multiline">{projectsContent}</div>
        </div>
        {this.props.limit === 1 ? (
          <div className="see_more">
            <Link to="/projects"> See more</Link>
          </div>
        ) : null}
      </section>
    );
  }
}

Projects.propTybes = {
  getAllProjects: PropTypes.func.isRequired,
  getAllLimitProjects: PropTypes.func.isRequired,
  project: PropTypes.object.isRequired,
  limit: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  project: state.project,
});

export default connect(mapStateToProps, {
  getAllProjects,
  getAllLimitProjects,
})(Projects);

import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { viewProject } from "../../actions/projectsActions";

class ProjectItem extends Component {
  render() {
    const { project } = this.props;

    return (
      <div className="column">
        <div className="card" style={{ height: "100%" }}>
          <div className="card-image">
            <a href={`/projects/${project._id}`}>
              <img src={project.image} alt="" />
            </a>
          </div>
          <div className="card-content">
            <span className="is-size-7">25 Jun 2019</span>
            <h5 className="title is-5">
              <a href={`/projects/${project._id}`}>{project.name}</a>
            </h5>
            {/* project.technologies.map((tech, index) => {
                    return <li key={`${tech}_index`}>{tech}</li>;
                  }) */}
            <a className="button is-primary" href={`/projects/${project._id}`}>
              Read more
            </a>
          </div>
        </div>
      </div>
    );
  }
}

ProjectItem.propTypes = {
  project: PropTypes.object.isRequired,
  viewProject: PropTypes.func.isRequired,
};

export default connect(null, { viewProject })(ProjectItem);

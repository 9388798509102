import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getprojectById } from "../../actions/projectsActions";
import Spinner from "../../components/common/Spinner";
import Code from "../../images/icons/chevron-right.svg";
import Chrome from "../../images/icons/chrome.svg";

class ProjectView extends Component {
  saveState() {
    this.props.getprojectById(this.props.match.params.id);
  }

  componentDidMount() {
    //this.saveState();
    this.props.getprojectById(this.props.match.params.id);
    //window.addEventListener("onbeforeunload", this.saveState);
  }

  componentWillUnmount() {
    //window.removeEventListener("beforeunload", this.saveState);
  }

  isEmpty = (value) =>
    value === undefined ||
    value === null ||
    (typeof value === "object" && Object.keys(value).length === 0) ||
    (typeof value === "string" && value.trim().length === 0);

  render() {
    const { project, loading } = this.props.project;
    let projectContent;

    if (this.isEmpty(project) || loading) {
      projectContent = <Spinner />;
    } else
      projectContent = (
        <section className="section">
          <div className="container py-4">
            <h2 className="title has-text-centered mb-6 is-capitalized">
              {project.name}
            </h2>
            <div className="columns">
              <div className="column is-6">
                <h4 className="title is-spaced is-4 has-text-centered is-capitalized">
                  {project.topic}
                </h4>
                <hr></hr>
                <p className="subtitle is-capitalized is-spaced">
                  {project.description}
                </p>
                <div>
                  <div className="media">
                    <div className="media-left">
                      <figure className="image is-24x24">
                        <img src={Code} alt="" />
                      </figure>
                    </div>
                    <div className="media-content">
                      <div className="content">
                        <p>
                          {project.technologies.map((tech, index) => {
                            return (
                              <React.Fragment>
                                <span
                                  className=" is-size-6"
                                  key={`${tech}_inde`}
                                >
                                  <b className="space-right "> {tech} </b>
                                </span>
                                {index + 2 > project.technologies.length
                                  ? null
                                  : "-"}
                              </React.Fragment>
                            );
                          })}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="media">
                    <div className="media-left">
                      <figure className="image is-24x24">
                        <img src={Chrome} alt="" />
                      </figure>
                    </div>
                    <div className="media-content">
                      <div className="content">
                        <a
                          href={project.demo_link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Live Demo
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="column is-5 is-offset-1">
                <img src={project.image} alt="" />
              </div>
            </div>
          </div>
        </section>
      );
    return projectContent;
  }
}

ProjectView.propTypes = {
  project: PropTypes.object.isRequired,
  getprojectById: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  project: state.project,
  errors: state.errors,
});

export default connect(mapStateToProps, { getprojectById })(ProjectView);

import React, { Component } from "react";

import LinkedIn from "../../images/icons/linkedin-in.svg";
import Twitter from "../../images/icons/twitter.svg";
import Facebook from "../../images/icons/facebook-f.svg";
import Instagram from "../../images/icons/instagram.svg";
import Github from "../../images/icons/github.svg";

class Footer extends Component {
  render() {
    return (
      <footer className="footer">
        <div className="container">
          <div className="level">
            <div className="level-left">
              <div className="level-item">
                <a className="title is-4" href="/">
                  Mahmod Hasan
                </a>
              </div>
            </div>
            <div className="level-right">
              <a className="level-item" href="/projects">
                Projects
              </a>
              <a className="level-item" href="/enterprise">
                Enterprise
              </a>
              <a className="level-item" href="/contact">
                Contact
              </a>
              <a className="level-item" href="/blog">
                Blog
              </a>
              <a className="level-item" href="/get-to-know-me">
                Get-to-know-me
              </a>
            </div>
          </div>
          <hr />
          <div className="columns">
            <div className="column is-narrow has-text-centered">
              <div className="buttons has-text-left-tablet is-centered  is-vcentered is-mobile">
                <a
                  className="button "
                  href="https://www.linkedin.com/in/mahmod-hasn/"
                  title="LinkedIn"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={LinkedIn} alt="LinkedIn" />
                </a>
                <a
                  className="button"
                  href="https://twitter.com/MAHMUOD_HASAN"
                  title="Twitter"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={Twitter} alt="Twitter" />
                </a>
                <a
                  className="button"
                  href="https://github.com/shadowzack"
                  title="Github"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={Github} alt="Github" />
                </a>
                <a
                  className="button"
                  href="https://www.facebook.com/mahmod.hasn.97"
                  title="Facebook"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={Facebook} alt="Facebook" />
                </a>
                <a
                  className="button"
                  href="https://www.instagram.com/mahmodhasn/"
                  title="Instagram"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={Instagram} alt="Instagram" />
                </a>
              </div>
            </div>
            <div className="column has-text-centered has-text-right-tablet">
              <p className="subtitle is-6">
                © {new Date().getFullYear()} Mahmod Hasan. All right reserved.
              </p>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;

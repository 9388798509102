//auth
export const SET_CURRENT_USER = "SET_CURRENT_USER";

//errors
export const GET_ERRORS = "GET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";

//projects
export const GET_PROJECTS = "GET_PROJECTS";
export const GET_PROJECT = "GET_PROJECT";
export const INSERT_PROJECT = "INSERT_PROJECT";
export const DELETE_PROJECT = "DELETE_PROJECT";
export const PROJECT_LOADING = "PROJECT_LOADING";
export const UPDATE_PROJECT = "UPDATE_PROJECT";
export const PROJECT_LOADING_POST = "PROJECT_LOADING_POST";

//conatct
export const SEND_MESSAGE = "SEND_MESSAGE";
export const MESSAGE_LOADING = "MESSAGE_LOADING";


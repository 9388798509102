import {combineReducers} from "redux";
import authReducer from "./authReducer";
import errorReducer from "./errorReducer";
import projectReducer from "./projectReducer";
import messageReducer from "./messageReducer";

export default combineReducers({
  auth: authReducer,
  errors: errorReducer,
  project: projectReducer,
  message: messageReducer
});

import {
  GET_PROJECTS,
  PROJECT_LOADING,
  GET_PROJECT,
  INSERT_PROJECT,
  PROJECT_LOADING_POST,
} from "../actions/types";

const initialState = {
  projects: [],
  project: {},
  loading: false,
  loadingPOST: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_PROJECTS:
      return {
        ...state,
        projects: action.payload,
        loading: false,
      };
    case PROJECT_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_PROJECT:
      /*  let pos = state.projects
        .map(p => {
          return p._id;
        })
        .indexOf(action.payload); */

      return {
        ...state,
        project: action.payload,
        loading: false,
      };
    case INSERT_PROJECT:
      return {
        ...state,
        projects: state.projects.concat(action.payload),
        loading: false,
        loadingPOST: false,
      };
    case PROJECT_LOADING_POST:
      return {
        ...state,
        loadingPOST: !state.loadingPOST,
      };
    default:
      return state;
  }
}

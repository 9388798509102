import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { sendMessage } from "../actions/contactActions";
import Mail from "../images/icons/envelope.svg";
import Phone from "../images/icons/phone.svg";
import Map from "../images/icons/map-marker.svg";
class Contact extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      email: "",
      message: "",
      errors: {},
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onSubmit(e) {
    e.preventDefault();
    const newMessage = {
      name: this.state.name,
      email: this.state.email,
      message: this.state.message,
    };

    console.log(newMessage);
    this.props.sendMessage(newMessage);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }

  render() {
    const { errors } = this.state;
    return (
      <div>
        <section className="section">
          <div className="container py-4">
            <h2 className="title has-text-centered mb-6">Contact me</h2>
            <div className="columns">
              <div className="column is-6">
                <h4 className="title is-spaced is-4">
                  Let's talk about development, technology, gaming and more.
                </h4>
                <p className="subtitle">
                  I'm here to answer your questions and discuss tech stuff and
                  maybe gaming stuff who knows.
                </p>
                <div>
                  <div className="media">
                    <div className="media-left">
                      <figure className="image is-24x24">
                        <img src={Map} alt="" />
                      </figure>
                    </div>
                    <div className="media-content">
                      <div className="content">
                        <p>Mahmod Hasan HQ - Home</p>
                      </div>
                    </div>
                  </div>
                  <div className="media">
                    <div className="media-left">
                      <figure className="image is-24x24">
                        <img src={Phone} alt="" />
                      </figure>
                    </div>
                    <div className="media-content">
                      <div className="content">
                        <a href="tel:+972505755810">+972505755810</a>
                      </div>
                    </div>
                  </div>
                  <div className="media">
                    <div className="media-left">
                      <figure className="image is-24x24">
                        <img src={Mail} alt="" />
                      </figure>
                    </div>
                    <div className="media-content">
                      <div className="content">
                        <a href="mailto:mahmod_hasan@outlook.com">
                          mahmod_hasan@outlook.com
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="column is-5 is-offset-1">
                <form onSubmit={this.onSubmit}>
                  <div className="field">
                    <div className="control">
                      <input
                        className="input"
                        type="text"
                        placeholder="Name"
                        name="name"
                        value={this.state.name}
                        onChange={this.onChange}
                        error={errors.name}
                      />
                    </div>
                  </div>
                  <div className="field">
                    <div className="control">
                      <input
                        className="input"
                        type="email"
                        name="email"
                        placeholder="Email"
                        value={this.state.email}
                        onChange={this.onChange}
                        error={errors.email}
                      />
                    </div>
                  </div>
                  <div className="field">
                    <div className="select is-fullwidth">
                      <select>
                        <option disabled selected>
                          Select Topic
                        </option>
                        <option>work proposition</option>
                        <option>blog stuff</option>
                        <option>other</option>
                      </select>
                    </div>
                  </div>
                  <div className="field">
                    <div className="control">
                      <textarea
                        className="textarea"
                        rows={5}
                        placeholder="Write something..."
                        defaultValue={""}
                        name="message"
                        value={this.state.message}
                        onChange={this.onChange}
                        error={errors.message}
                      />
                    </div>
                  </div>
                  <div className="field">
                    <div className="control">
                      <button
                        className="button is-primary is-fullwidth"
                        type="submit"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
        {/*   <section className="section">
          <div className="container has-text-centered">
            <h2 className="title is-spaced">Future of the Internet</h2>
            <p className="subtitle">what are you doing here</p>
            <a className="button is-primary" href="#">
              Join the Revolution!
            </a>
          </div>
        </section> */}
      </div>
    );
  }
}

Contact.propTypes = {
  sendMessage: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  errors: state.errors,
});

export default connect(mapStateToProps, { sendMessage })(withRouter(Contact));

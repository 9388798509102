import React, { Component } from "react";

class NotFound extends Component {
  render() {
    return (
      <div className="container is-fluid">
        <section className="section">
          <div className="container has-text-centered">
            <div className="columns is-centered">
              <div className="column is-7">
                <h1 className="title is-1">404</h1>
                <p className="subtitle is-3">Page not found</p>
                <p>
                  Well, it looks like your lost... try to go back or click the button below to go back home.
                </p>
              </div>
            </div>
            <a className="button is-primary" href="/">
              Back to homepage
            </a>
          </div>
        </section>
      </div>
    );
  }
}

export default NotFound;

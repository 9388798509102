import axios from "axios";
import {
  GET_ERRORS,
  CLEAR_ERRORS,
  INSERT_PROJECT,
  GET_PROJECTS,
  GET_PROJECT,
  PROJECT_LOADING_POST,
  PROJECT_LOADING,
} from "./types";
import { URL } from "./consts";

// AddProject
export const AddProject = (project) => (dispatch) => {
  dispatch(clearErrors());
  let configData = {
    method: "post",
    url: `${URL}/projects/create`,
    data: project,
    headers: { "Content-Type": "multipart/form-data" },
  };
  dispatch(setProjectLoadingPOST());
  axios(configData)
    .then((result) => {
      dispatch({
        type: INSERT_PROJECT,
        payload: result.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      dispatch(setProjectLoadingPOST());
    });
};

//get all projects
export const getAllProjects = () => (dispatch) => {
  setProjectLoading();
  axios
    .get(`${URL}/projects/getAll`)
    .then((result) => {
      dispatch({
        type: GET_PROJECTS,
        payload: result.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

//get all limit projects
export const getAllLimitProjects = (num) => (dispatch) => {
  setProjectLoading();
  axios
    .get(`${URL}/projects/getAll/${num}`)
    .then((result) => {
      dispatch({
        type: GET_PROJECTS,
        payload: result.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

//get project by id
export const getprojectById = id => dispatch => {
  dispatch(setProjectLoading());
  axios
    .get(`${URL}/projects/${id}`)
    .then(result => {
      dispatch({
        type: GET_PROJECT,
        payload: result.data
      });
    })
    .catch(err => {
      dispatch({
        type: GET_PROJECT,
        payload: null
      });
    });
}; 

//view project page
export const viewProject = (id) => (dispatch) => {
  /* dispatch(setProjectLoading());
  axios
    .get(`${URL}/projects/${id}`)
    .then((result) => {
      dispatch({
        type: GET_PROJECT,
        payload: result.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_PROJECT,
        payload: null,
      });
    }); */

  dispatch(setProjectLoading());
  dispatch({
    type: GET_PROJECT,
    payload: id,
  });
};

//set loading state
export const setProjectLoading = () => {
  return {
    type: PROJECT_LOADING,
  };
};

//clear errors
export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS,
  };
};

//set loadig post req
export const setProjectLoadingPOST = () => {
  return {
    type: PROJECT_LOADING_POST,
  };
};

import React, { Component } from "react";
import {
  Redirect,
  BrowserRouter as Router,
  Route,
  Switch,
} from "react-router-dom";
import jwtdecode from "jwt-decode";
import setAuthToken from "./utils/setAuthToken";
import { setCurrentUser, logoutUser } from "./actions/authActions";
import { Provider } from "react-redux";
import store from "./store";

import NotFound from "./pages/NotFound";
import ScrollToTop from "./components/common/ScrollToTop";
import Footer from "./components/layout/Footer";
import Navigation from "./components/layout/Navigation";
import Home from "./pages/Home";
import Projects from "./pages/Projects/Projects";
import Enterprise from "./pages/Enterprise";
import Contact from "./pages/Contact";
import Blog from "./pages/Blog";
import Signin from "./pages/Signin";
import GetToKnowMe from "./pages/GetToKnowMe";
import ProjectView from "./pages/Projects/ProjectView";

//jwt token setup for private routes
if (localStorage.jwtToken) {
  //set auth token header auth
  setAuthToken(localStorage.jwtToken);
  //decode token to get the user info
  const decoded = jwtdecode(localStorage.jwtToken);
  store.dispatch(setCurrentUser(decoded));
  //check for expired token
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    store.dispatch(logoutUser());
    //redirect to login
    window.location.href = "/Login";
  }
}

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Router>
          <ScrollToTop />
          <Switch>
            <Navigation />
          </Switch>
          <main>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/projects" limit={true} component={Projects} />
              <Route exact path="/projects/:id" component={ProjectView} />
              <Route exact path="/enterprise" component={Enterprise} />
              <Route exact path="/contact" component={Contact} />
              <Route exact path="/blog" component={Blog} />
              <Route exact path="/signin" component={Signin} />
              <Route exact path="/get-to-know-me" component={GetToKnowMe} />
              <Route path="/404" component={NotFound} />
              <Redirect to="/404" />
            </Switch>
          </main>
          <Footer />
        </Router>
      </Provider>
    );
  }
}

export default App;

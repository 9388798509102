import React, { Component } from "react";

class GetToKnowMe extends Component {
  render() {
    return (
      <iframe
        src="https://drive.google.com/file/d/12_-5j8Ub52E8g06noXqREaDAMUMPyRYU/preview?resourcekey=null"
        height="100%"
        width="100%"
        title="my resume"
        frameBorder="0"
        scrolling="auto"
        style={{ minHeight: 1000 }}
      ></iframe>
    );
  }
}

export default GetToKnowMe;

import React, { Component } from "react";
import PropTypes from "prop-types";
import ProjectItem from "./ProjectItem";

/**Projects Feed */
class projectsFeed extends Component {
  render() {
    const { projects, limit } = this.props;
    let len =
      limit !== undefined && limit === true && projects.length > 4
        ? 4
        : projects.length;

    return projects.slice(0, len).map((project, index) => {
      let count = 0;
      for (let index = 0; index < project.technologies.length; index++)
        if (
          this.props.selected !== "All" &&
          project.technologies[index] !== this.props.selected
        )
          count++;

      if (count === project.technologies.length) return "";
      else return <ProjectItem key={project._id} project={project} />;
    });
  }
}

projectsFeed.propTypes = {
  projects: PropTypes.array.isRequired,
  limit: PropTypes.bool,
  selected: PropTypes.string.isRequired
};

export default projectsFeed;

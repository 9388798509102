import axios from "axios";
import jwtdecode from "jwt-decode";
import { GET_ERRORS, SET_CURRENT_USER, CLEAR_ERRORS } from "./types";
import { URL } from "./consts";
import setAuthToken from "../utils/setAuthToken";

// User Login get token
export const loginUser = user => dispatch => {
  axios
    .post(`${URL}/users/login`, user)
    .then(result => {
      const { token } = result.data;
      localStorage.setItem("jwtToken", token);
      //set token to auth header
      setAuthToken(token);
      //decode token to get user data
      const decoded = jwtdecode(token);
      //set current user
      dispatch(setCurrentUser(decoded));
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

// Set current user with the decoded token
export const setCurrentUser = decoded => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded
  };
};

//clear errors
export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS
  };
};

// User Logout
export const logoutUser = () => dispatch => {
  //remove token
  localStorage.removeItem("jwtToken");
  //remove auth header for furture reqest
  setAuthToken(false);
  //set current user to {} wich will also set is authnticated to false
  dispatch(setCurrentUser({}));
};

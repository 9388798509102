import React, { Component } from "react";

class Blog extends Component {
  render() {
    return (
      <section className="section">
        <div className="container py-4">
          <h2 className="title has-text-centered mb-6">Recent Blogs</h2>
          <div className="columns is-multiline has-text-centered is-centered">No Blogs Yet</div>
        </div>
      </section>
    );
  }
}

export default Blog;

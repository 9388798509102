import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

class OptionsFeed extends Component {
  constructor() {
    super();
    this.state = {
      Array: [
        "All",
        "ReactJS/Redux",
        "NodeJS/Express",
        "MongoDB",
        "HTML/CSS",
        "PHP",
        "MySQL",
        "Java/Android"
      ]
    };
  }
  render() {
    return this.state.Array.map((project, index) => (
      <li key={project}>
        <Link
          to="#"
          onClick={this.props.setSelected}
          className={
            this.props.selected === project.toString() ? "selectedList" : null
          }
        >
          {project.toString()}
        </Link>
      </li>
    ));
  }
}

OptionsFeed.propTybes = {
  setSelected: PropTypes.func.isRequired,
  selected: PropTypes.object.isRequired
};

export default OptionsFeed;

import axios from "axios";
import {
  GET_ERRORS,
  CLEAR_ERRORS,
  SEND_MESSAGE,
  MESSAGE_LOADING
} from "./types";
import { URL } from "./consts";

// send message
export const sendMessage = message => dispatch => {
  dispatch(clearErrors());
  dispatch(setMessageLoading());
  axios
    .post(`${URL}/contact/sendMessage`, message)
    .then(result => {
      dispatch({
        type: SEND_MESSAGE,
        payload: result.data
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      });
      dispatch(setMessageLoading());
    });
};

//set loading state
export const setMessageLoading = () => {
  return {
    type: MESSAGE_LOADING
  };
};

//clear errors
export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS
  };
};
